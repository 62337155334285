// src/apolloClient.js
import { ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

// Load environment variables
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:4000/graphql'; // Default to localhost if not set
const WS_ENDPOINT = process.env.REACT_APP_WS_ENDPOINT || 'ws://localhost:4000/graphql'; // Default to localhost if not set
const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY || 'auth_token'; // Default token key

// HTTP link to your GraphQL API
const httpLink = createHttpLink({
  uri: API_ENDPOINT, // Use environment variable for the endpoint
});

// Auth link to include authentication token in headers
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(TOKEN_KEY); // Use environment variable for the token key
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  };
});

// Error link for handling GraphQL and network errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

// WebSocket link for subscriptions
const wsLink = new WebSocketLink({
  uri: WS_ENDPOINT, // Use environment variable for the WebSocket endpoint
  options: {
    reconnect: true,
  },
});

// Split links for handling subscriptions with WebSocket and others with HTTP
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(errorLink.concat(httpLink))
);

// Apollo Client setup with split links and cache
const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

export default client;
